import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

//bootstrap vue
import { BootstrapVue } from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
Vue.use(BootstrapVue);
// Vue.config.devtools = false
//perfect scrollbar
import PerfectScrollbar from 'vue2-perfect-scrollbar';
Vue.use(PerfectScrollbar);

//vue-scrollactive
import VueScrollactive from 'vue-scrollactive';
Vue.use(VueScrollactive);

//vue-meta
import VueMeta from 'vue-meta';
Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
});
//ElementUI
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/en";
Vue.use(ElementUI, { locale });

//portal vue
import PortalVue from 'portal-vue';
Vue.use(PortalVue);

//vue-i18n
import i18n from './i18n';

Vue.config.productionTip = false;

// set default settings
import appSetting from './app-setting';
Vue.prototype.$appSetting = appSetting;
appSetting.init();

import helper from "@/helper";

import axios from 'axios';
import VueAxios from 'vue-axios';
Vue.use(VueAxios, axios);
window.axios = axios;

import loading_button from "@/views/elements/loading_button";
import flashMessage from "@/views/alert";
Vue.component('loading_button', loading_button);
Vue.component('alert', flashMessage);


let global_token = sessionStorage.getItem('user');

if (!global_token)
{
    let local_token = localStorage.getItem('user');

    if (local_token)
    {
        global_token = local_token;
        sessionStorage.setItem('user',local_token);
    }
}


router.beforeEach((to, from, next) => {

    if (to.meta && to.meta.layout && to.meta.layout == 'auth') {
        store.commit('setLayout', 'auth');
    } else {
        store.commit('setLayout', 'app');
    }

    if (to.matched.some(record => record.meta.auth)) {
        let user = JSON.parse(sessionStorage.getItem('user'));
        let token = store.getters.getAuthToken;
        let user_token = sessionStorage.getItem('token');
        if (user && user.user_type == 1)
        {
            store.commit('setAuthToken',token ? token : user_token);
            store.commit('setAuthUser',user);
            next();
        }
        else{
            localStorage.removeItem('token');
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('user');
            store.commit('setAuthUser',{});
            next({ name: 'login' });
        }

    } else {
        next() // does not require auth, make sure to always call next()!
    }
})

new Vue({
    router,
    store,
    mixins: [helper],
    i18n,
    render: h => h(App)
}).$mount('#app');

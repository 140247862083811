<template>
  <div v-if="response">
    <b-alert
        show
        variant="light-danger"
        dismissible
        @dismissed="dismissCountDown"
        class="alert-icon-left mb-4"
        v-if="response.toastr_type == 'error'"
    >
      <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-alert-octagon"
      >
        <polygon
            points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"
        ></polygon>
        <line x1="12" y1="8" x2="12" y2="12"></line>
        <line x1="12" y1="16" x2="12.01" y2="16"></line>
      </svg>
      <strong>{{ response.toastr_title }}!</strong> {{ response.toastr_msg }}
    </b-alert>

    <!--this for success alert-->
    <b-alert
        show
        @dismissed="dismissCountDown"
        dismissible
        variant="light-success"
        class="alert-icon-left mb-4"
        v-if="response.toastr_type == 'success'"
    >
      <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-check-square"
      >
        <polyline points="9 11 12 14 22 4"></polyline>
        <path
            d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"
        ></path>
      </svg>
      <strong>{{ response.toastr_title }}!</strong> {{ response.toastr_msg }}
    </b-alert>

    <!--this for warning alert -->
    <b-alert
        show
        dismissible
        @dismissed="dismissCountDown"
        variant="light-warning"
        class="alert-icon-left mb-4"
        v-if="response.toastr_type == 'warning'"
    >
      <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-alert-triangle"
      >
        <path
            d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"
        ></path>
        <line x1="12" y1="9" x2="12" y2="13"></line>
        <line x1="12" y1="17" x2="12" y2="17"></line>
      </svg>
      <strong>{{ response.toastr_title }}!</strong> {{ response.toastr_msg }}
    </b-alert>

    <!--this for info alert -->
    <b-alert
        v-if="response.toastr_type == 'info'"
        show
        dismissible
        @dismissed="dismissCountDown"
        variant="light-info"
        class="alert-icon-left mb-4">
      <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-info"
      >
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="12" y1="16" x2="12" y2="12"></line>
        <line x1="12" y1="8" x2="12.01" y2="8"></line>
      </svg>
      <strong>Info!</strong> Lorem Ipsum is simply dummy printing.
    </b-alert>
  </div>
</template>
<script>
export default {
  data(){
    return{
      dismissSecs: 5,
    }
  },
 computed:{
   response(){
     return this.$store.getters.getFlashResponse
   },

 },
  methods:{
    dismissCountDown() {
      this.$store.commit('getFlashResponse',{});
    },
  }

}
</script>

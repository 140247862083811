<template>
	<!--  BEGIN SIDEBAR  -->
	<div class="sidebar-wrapper sidebar-theme">
		<nav ref="menu" id="sidebar">
			<div class="shadow-bottom"></div>

			<perfect-scrollbar
				class="list-unstyled menu-categories"
				tag="ul"
				:options="{
					wheelSpeed: 0.5,
					swipeEasing: !0,
					minScrollbarLength: 40,
					maxScrollbarLength: 300,
					suppressScrollX: true,
				}"
			>
				<li class="menu">
					<router-link
						tag="li"
						to="/"
						class="menu"
						@click.native="toggleMobileMenu"
					>
						<a class="dropdown-toggle">
							<div class="">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									stroke-width="2"
									stroke-linecap="round"
									stroke-linejoin="round"
									class="feather feather-layout"
								>
									<rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
									<line x1="3" y1="9" x2="21" y2="9"></line>
									<line x1="9" y1="21" x2="9" y2="9"></line>
								</svg>
								<span>{{ $t("dashboard") }}</span>
							</div>
						</a>
					</router-link> 
				</li>

				<li class="menu">
					<a href="#users" v-b-toggle class="dropdown-toggle" @click.prevent>
						<div class="">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
								class="feather feather-users"
							>
								<path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
								<circle cx="9" cy="7" r="4"></circle>
								<path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
								<path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
							</svg>
							<span>{{ $t("users") }}</span>
						</div>
						<div>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
								class="feather feather-chevron-right"
							>
								<polyline points="9 18 15 12 9 6"></polyline>
							</svg>
						</div>
					</a>
					<b-collapse id="users" accordion="menu">
						<ul class="collapse submenu list-unstyled show">
							<router-link
								tag="li"
								to="/users/admin"
								@click.native="toggleMobileMenu"
								><a>Administrator</a></router-link
							>
							<router-link
								tag="li"
								to="/users/buyer"
								@click.native="toggleMobileMenu"
								><a>Buyer/Customer</a></router-link
							>
							<router-link
								tag="li"
								to="/users/seller"
								@click.native="toggleMobileMenu"
								><a>Seller/Dealer</a></router-link
							>
						</ul>
					</b-collapse>
				</li> 
				<li class="menu">
					<a
						href="#cars-info"
						v-b-toggle
						class="dropdown-toggle"
						@click.prevent
					>
						<div class="">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
								class="feather feather-git-pull-request"
							>
								<circle cx="18" cy="18" r="3"></circle>
								<circle cx="6" cy="6" r="3"></circle>
								<path d="M13 6h3a2 2 0 0 1 2 2v7"></path>
								<line x1="6" y1="9" x2="6" y2="21"></line>
							</svg>
							<span>Car Info</span>
						</div>
						<div>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
								class="feather feather-chevron-right"
							>
								<polyline points="9 18 15 12 9 6"></polyline>
							</svg>
						</div>
					</a>
					<b-collapse id="cars-info" accordion="menu">
						<ul class="collapse submenu list-unstyled show">
							<router-link
								tag="li"
								to="/car/makes"
								@click.native="toggleMobileMenu"
								><a>Makes</a></router-link
							>
							<router-link
								tag="li"
								to="/car/models"
								@click.native="toggleMobileMenu"
								><a>Models</a></router-link
							>
							<router-link
								tag="li"
								to="/car/years"
								@click.native="toggleMobileMenu"
								><a>Years</a></router-link
							>
							<router-link
								tag="li"
								to="/car/colors"
								@click.native="toggleMobileMenu"
								><a>Colors</a></router-link
							>
							<router-link
								tag="li"
								to="/car/drive-type"
								@click.native="toggleMobileMenu"
								><a>Drive Type</a></router-link
							>
							<router-link
								tag="li"
								to="/car/transmission"
								@click.native="toggleMobileMenu"
								><a>Transmission</a></router-link
							>
<!--							<router-link
								tag="li"
								to="/car/condition"
								@click.native="toggleMobileMenu"
								><a>Condition</a></router-link
							>-->
							<router-link
								tag="li"
								to="/car/body-style"
								@click.native="toggleMobileMenu"
								><a>Body Style</a></router-link
							>
							<router-link
								tag="li"
								to="/car/fuel-type"
								@click.native="toggleMobileMenu"
								><a>Fuel Type</a></router-link
							>
							<router-link
								tag="li"
								to="/car/car-features"
								@click.native="toggleMobileMenu"
								><a>Car Features</a></router-link
							>
						</ul>
					</b-collapse>
				</li>
				<li class="menu">
					<a href="#cars" v-b-toggle class="dropdown-toggle" @click.prevent>
						<div class="">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
								class="feather feather-file-text"
							>
								<path
									d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"
								></path>
								<polyline points="14 2 14 8 20 8"></polyline>
								<line x1="16" y1="13" x2="8" y2="13"></line>
								<line x1="16" y1="17" x2="8" y2="17"></line>
								<polyline points="10 9 9 9 8 9"></polyline>
							</svg>
							<span>Listing Cars</span>
						</div>
						<div>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
								class="feather feather-chevron-right"
							>
								<polyline points="9 18 15 12 9 6"></polyline>
							</svg>
						</div>
					</a>
					<b-collapse id="cars" accordion="menu">
						<ul class="collapse submenu list-unstyled show">
							<router-link
								tag="li"
                to="/listing/new-cars"
                @click.native="toggleMobileMenu"
								><a>New Cars</a></router-link>
							<router-link
								tag="li"
								to="/listing/used-cars"
								@click.native="toggleMobileMenu"
								><a>Used Cars</a></router-link>
							<router-link
								tag="li"
								to="/listing/super-cars"
								@click.native="toggleMobileMenu"
								><a>Super Cars</a></router-link
							>
						</ul>
					</b-collapse>
				</li>
				<router-link
						tag="li"
						to="/dealer-requests"
						class="menu"
						@click.native="toggleMobileMenu"
					>
						<a class="dropdown-toggle">
							<div class="">
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-pocket" data-v-8d2239c6=""><path d="M4 3h16a2 2 0 0 1 2 2v6a10 10 0 0 1-10 10A10 10 0 0 1 2 11V5a2 2 0 0 1 2-2z"></path><polyline points="8 10 12 14 16 10"></polyline></svg>
								<span>Dealer Requests</span>
							</div>
						</a>
					</router-link>
				<router-link
						tag="li"
						to="/apps/mailbox"
						class="menu"
						@click.native="toggleMobileMenu"
					>
						<a class="dropdown-toggle">
							<div class="">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									stroke-width="2"
									stroke-linecap="round"
									stroke-linejoin="round"
									class="feather feather-mail"
								>
									<path
										d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
									></path>
									<polyline points="22,6 12,13 2,6"></polyline>
								</svg>
								<span>Message</span>
							</div>
						</a>
					</router-link>
					
				<!--<router-link
					tag="li"
					to="/nft/list"
					class="menu"
					@click.native="toggleMobileMenu"
				>
					<a class="dropdown-toggle">
						<div class="">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
								class="feather feather-copy"
							>
								<rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
								<path
									d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"
								></path>
							</svg>
							<span>NFT</span>
						</div>
					</a>
				</router-link> -->
				<li class="menu">
					<a href="#order" v-b-toggle class="dropdown-toggle" @click.prevent>
						<div class="">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
								class="feather feather-shopping-bag"
							>
								<path
									d="M6 2L3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4z"
								></path>
								<line x1="3" y1="6" x2="21" y2="6"></line>
								<path d="M16 10a4 4 0 0 1-8 0"></path>
							</svg>
							<span>Order</span>
						</div>
						<div>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
								class="feather feather-chevron-right"
							>
								<polyline points="9 18 15 12 9 6"></polyline>
							</svg>
						</div>
					</a>
					<b-collapse id="order" accordion="menu">
						<ul class="collapse submenu list-unstyled show">
							<router-link
								tag="li"
								to="/order/list"
								@click.native="toggleMobileMenu"
								><a>Order Listing</a></router-link
							>
						</ul>
					</b-collapse>
				</li>

				<router-link
					tag="li"
					to="/pages/faqs"
					class="menu"
					@click.native="toggleMobileMenu"
				>
					<a class="dropdown-toggle">
						<div class="">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
								class="feather feather-help-circle"
							>
								<circle cx="12" cy="12" r="10"></circle>
								<path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
								<line x1="12" y1="17" x2="12.01" y2="17"></line>
							</svg>
							<span>Faq's</span>
						</div>
					</a>
				</router-link>
				<router-link
					tag="li"
					to="/pages/teams"
					class="menu"
					@click.native="toggleMobileMenu"
				>
					<a class="dropdown-toggle">
						<div class="">
							<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-briefcase" data-v-8d2239c6=""><rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect><path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path></svg>
							<span>Our Teams</span>
						</div>
					</a>
				</router-link>
				<router-link
					tag="li"
					to="/pages/testimonials"
					class="menu"
					@click.native="toggleMobileMenu"
				>
					<a class="dropdown-toggle">
						<div class="">
							<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-pen-tool" data-v-8d2239c6=""><path d="M12 19l7-7 3 3-7 7-3-3z"></path><path d="M18 13l-1.5-7.5L2 2l3.5 14.5L13 18l5-5z"></path><path d="M2 2l7.586 7.586"></path><circle cx="11" cy="11" r="2"></circle></svg>
							<span>Seller Testimonials</span>
						</div>
					</a>
				</router-link>
				<router-link
					tag="li"
					:to="{ name : 'footer.page' }"
					class="menu"
					@click.native="toggleMobileMenu"
				>
					<a class="dropdown-toggle">
						<div class="">
							<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-credit-card" data-v-8d2239c6=""><rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect><line x1="1" y1="10" x2="23" y2="10"></line></svg>
							<span>Content Pages</span>
						</div>
					</a>
				</router-link>

				<li class="menu">
					<a href="#blog" v-b-toggle class="dropdown-toggle" @click.prevent>
						<div class="">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
								class="feather feather-hexagon"
							>
								<path
									d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"
								></path>
							</svg>
							<span>Blog</span>
						</div>
						<div>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
								class="feather feather-chevron-right"
							>
								<polyline points="9 18 15 12 9 6"></polyline>
							</svg>
						</div>
					</a>
					<b-collapse id="blog" accordion="menu">
						<ul class="collapse submenu list-unstyled show">
							<router-link
								tag="li"
								to="/pages/blog"
								@click.native="toggleMobileMenu"
								><a>Post</a></router-link
							>
							<router-link
								tag="li"
								to="/pages/blog-category"
								@click.native="toggleMobileMenu"
								><a>Categories</a></router-link
							>
						</ul>
					</b-collapse>
          <router-link
              tag="li"
              to="/settings"
              class="menu"
              @click.native="toggleMobileMenu"
          >
            <a class="dropdown-toggle">
              <div class="">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-cpu"><rect x="4" y="4" width="16" height="16" rx="2" ry="2"></rect><rect x="9" y="9" width="6" height="6"></rect><line x1="9" y1="1" x2="9" y2="4"></line><line x1="15" y1="1" x2="15" y2="4"></line><line x1="9" y1="20" x2="9" y2="23"></line><line x1="15" y1="20" x2="15" y2="23"></line><line x1="20" y1="9" x2="23" y2="9"></line><line x1="20" y1="14" x2="23" y2="14"></line><line x1="1" y1="9" x2="4" y2="9"></line><line x1="1" y1="14" x2="4" y2="14"></line></svg>
                <span>Deposit Settings</span>
              </div>
            </a>
          </router-link>
				</li>
			</perfect-scrollbar>
		</nav>
	</div>
	<!--  END SIDEBAR  -->
</template>
<script>
	export default {
		data() {
			return { menu_collapse: "dashboard",      page:1,
      };
		},
		watch: {
			$route(to) {
				const selector = document.querySelector(
					'#sidebar a[href="' + to.path + '"]'
				);
        if (selector)
        {
          const ul = selector.closest("ul.collapse");
          if (!ul) {
            const ele = document.querySelector(".dropdown-toggle.not-collapsed");
            if (ele) {
              ele.click();
            }
          }
        }
			},
		},
		mounted() {
			const selector = document.querySelector(
				'#sidebar a[href="' + window.location.pathname + '"]'
			);
			if (selector) {
				const ul = selector.closest("ul.collapse");
				if (ul) {
					let ele = ul.closest("li.menu").querySelectorAll(".dropdown-toggle");
					if (ele) {
						ele = ele[0];
						setTimeout(() => {
							ele.click();
						});
					}
				} else {
					selector.click();
				}
			}
		},
		methods: {
			toggleMobileMenu() {
				if (window.innerWidth < 991) {
					this.$store.commit(
						"toggleSideBar",
						!this.$store.state.is_show_sidebar
					);
				}
			},
		},
	};
</script>
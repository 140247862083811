import Vue from 'vue';
import Vuex from 'vuex';
import i18n from '../i18n';

Vue.use(Vuex);


export default new Vuex.Store({
    state: {
        url: document.getElementById('base_url').value,
        layout: 'app',
        is_show_sidebar: true,
        is_show_search: false,
        is_dark_mode: false,
        car_data: false,
        dark_mode: 'light',
        locale: null,
        menu_style: 'vertical',
        layout_style: 'full',
        table_option: {
            total_rows: 0,
            page_size: 10,
            total_pages: 0,
        },
        current_page: 1,
        car_makes: [],
        feature_categories: [],
        auth_user: '',
        profile: '',
        auth_token: '',
        car_models: [],
        countries: [],
        makes: [],
        colors: [],
        conditions:[],
        drive_types: [],
        transmissions: [],
        carConditions: [],
        body_styles: [],
        fuel_types: [],
        cars:[],
        features:[],
        flash_response:[],
        settings:[],
        countryList:[
            { code: 'zh', name: 'Chinese' },
            { code: 'da', name: 'Danish' },
            { code: 'en', name: 'English' },
            { code: 'fr', name: 'French' },
            { code: 'de', name: 'German' },
            { code: 'el', name: 'Greek' },
            { code: 'hu', name: 'Hungarian' },
            { code: 'it', name: 'Italian' },
            { code: 'ja', name: 'Japanese' },
            { code: 'pl', name: 'Polish' },
            { code: 'pt', name: 'Portuguese' },
            { code: 'ru', name: 'Russian' },
            { code: 'es', name: 'Spanish' },
            { code: 'sv', name: 'Swedish' },
            { code: 'tr', name: 'Turkish' }
        ],
    },
    mutations: {
        setLayout(state, payload) {
            return state.layout = payload;
        },
        setSettings(state, payload) {
            return state.settings = payload;
        },
        toggleSideBar(state, value) {
            return state.is_show_sidebar = value;
        },
        toggleSearch(state, value) {
            return state.is_show_search = value;
        },
        toggleLocale(state, value) {
            value = value || 'en';
            i18n.locale = value;
            localStorage.setItem('i18n_locale', value);
            return state.locale = value;
        },

        toggleDarkMode(state, value) {
            //light|dark|system
            value = value || 'light';
            localStorage.setItem('dark_mode', value);
            state.dark_mode = value;
            if (value == 'light') {
                state.is_dark_mode = false;
            } else if (value == 'dark') {
                state.is_dark_mode = true;
            } else if (value == 'system') {
                if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                    state.is_dark_mode = true;
                } else {
                    state.is_dark_mode = false;
                }
            }

            if (state.is_dark_mode) {
                document.querySelector('body').classList.add('dark');
            } else {
                document.querySelector('body').classList.remove('dark');
            }
        },

        toggleMenuStyle(state, value) {
            //horizontal|vertical|collapsible-vertical
            value = value || '';
            localStorage.setItem('menu_style', value);
            state.menu_style = value;
            if (!value || value === 'vertical') {
                return state.is_show_sidebar = true;
            } else if (value === 'collapsible-vertical') {
                return state.is_show_sidebar = false;
            }
        },

        toggleLayoutStyle(state, value) {
            //boxed-layout|large-boxed-layout|full
            value = value || '';
            localStorage.setItem('layout_style', value);
            return state.layout_style = value;
        },

        setTableOption(state, data) {
            return state.table_option = data;
        },
        setCurrentPage(state, num) {
            return state.current_page = num;
        },
        setCarMakes(state, data) {
            return state.car_makes = data;
        },
        setFeatureCategories(state, data) {
            return state.feature_categories = data;
        },
        setAuthUser(state, data) {
            return state.auth_user = data;
        },
        setAuthToken(state, data) {
            return state.auth_token = data;
        },
       setCarModels(state, data) {
           return state.car_models = data;
        },
        setCountry(state, data) {
            return state.countries = data;
        },
        setMake(state, data) {
            return state.makes = data;
        },
        setColors(state, data) {
            return state.colors = data;
        },
        setDriveTypes(state, data) {
            return state.drive_types = data;
        },
        setTransmission(state, data) {
            return state.transmissions = data;
        },
        setCondition(state, data) {
            return state.conditions = data;
        },
        setBodyStyle(state, data) {
            return state.body_styles = data;
        },
        setFuelType(state, data) {
            return state.fuel_types = data;
        },
        setCarData(state, data) {
            return state.car_data = data;
        },
        setCar(state, data){
            return state.cars = data;
        },
        setCarConditions(state, data){
            return state.carConditions = data;
        },
        getFlashResponse(state, data){
            return state.flash_response = data;
        },
        setFeature(state, data){
            return state.features = data;
        }
    },
    getters: {
        layout(state) {
            return state.layout;
        },
        getSettings(state) {
            return state.settings;
        },
        getTableOption(state) {
            return state.table_option;
        },
        getCurrentPage(state) {
            return state.current_page;
        },
        getCarMakes(state) {
            return state.car_makes;
        },
        getFeatureCategories(state) {
            return state.feature_categories;
        },
        getAuthUser(state) {
            return state.auth_user;
        },
        getAuthToken(state) {
            return state.auth_token;
        },
        getCarModels(state) {
            return state.car_models;
        },
        getMake(state) {
            return state.makes;
        },
        getCountry(state) {
            return state.countries;
        },
        getColors(state) {
            return state.colors;
        },
        getDriveTypes(state) {
            return state.drive_types;
        },
        getTransmission(state) {
            return state.transmissions;
        },
        getCondition(state) {
            return state.conditions;
        },
        getBodyStyle(state) {
            return state.body_styles;
        },
        getFuelType(state) {
            return state.fuel_types;
        },
        getCarData(state) {
            return state.car_data;
        },
        getCar(state) {
            return state.cars;
        },
        getCarConditions(state) {
            return state.carConditions;
        },
        getFlashResponse(state){
            return state.flash_response
        },
        getFeatures(state){
            return state.features
        }
    },
    actions: {
        carMakes(context){
            let url = context.state.url + '/active-makes?token='+context.state.auth_token;
            window.axios.get(url).then((response) =>{
                context.commit('setCarMakes',response.data.items);
            });
        },
        carConditions(context){
            let url = context.state.url + '/conditions?token='+context.state.auth_token;
            window.axios.get(url).then((response) =>{
                context.commit('setCarConditions',response.data.items);
            });
        },
        featureCategories(context){
            let url = context.state.url + '/active-categories?token='+context.state.auth_token;
            window.axios.get(url).then((response) =>{
                context.commit('setFeatureCategories',response.data.items);
            });
        },
        authUser(context){
            let token = sessionStorage.getItem('token');
            let url = context.state.url + '/profile?token='+token;
            window.axios.get(url).then((response) =>{
                context.commit('setAuthUser',response.data.user);
            });
        },
        carModels(context,make_id){
            let url = context.state.url + '/active-models/'+make_id +'token='+context.state.auth_token;
            window.axios.get(url).then((response) =>{
                context.commit('setCarModels',response.data.models);
            });
        },
        carData(context){
            context.commit('setCarData',true);
            let token = sessionStorage.getItem('token');
            let url = context.state.url + '/car-data?token='+token;
            window.axios.get(url).then((response) =>{
                if (response.data.success)
                {
                    let data = response.data;
                    context.commit('setMake',data.makes);
                    context.commit('setCountry',data.countries);
                    context.commit('setColors',data.colors);
                    context.commit('setDriveTypes',data.drive_types);
                    context.commit('setTransmission',data.transmissions);
                    context.commit('setCondition',data.conditions);
                    context.commit('setBodyStyle',data.body_styles);
                    context.commit('setFuelType',data.fuel_types);
                    context.commit('setFeatureCategories',data.feature_categories);
                    context.commit('setFeature',data.features);
                }
                else{
                    context.commit('setCarData',false);
                }
            }).catch(error => {
                if(error){
                    context.commit('setCarData',false);
                }
            });
        },
        getCategories(context){
            let token = sessionStorage.getItem('token');
            let url = context.state.url + '/get-categories?token='+token;
            window.axios.get(url).then((response) =>{
                if (response.data.success)
                {
                    let data = response.data;
                    context.commit('setFeatureCategories',data.feature_categories);
                }
            }).catch(error => {
                console.log(error);
            });
        },
        settings(context){
            let config = {
                headers: {
                    'Content-Type': "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
                    'Authorization': "Bearer "+sessionStorage.getItem('token')
                }
            };
            let url = context.state.url + '/settings';
            window.axios.get(url,config).then((response) =>{
                context.commit('setSettings',response.data.settings);
            }).catch(error => {
                console.log(error);
            });
        },
    },
    modules: {}
});

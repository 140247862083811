<template>
  <b-button :variant="variant" tag="a" v-if="type && type == 'a'" class="disable_btn" :class="class_name" ><b-spinner label="Spinning"></b-spinner></b-button>
  <b-button type="button" v-else :variant="variant" :class="class_name" disabled><b-spinner label="Spinning"></b-spinner></b-button>
</template>

<script>
export default {
  name: "loading_button",
  props : ['variant','class_name','type']
}
</script>

<style scoped>
.spinner-border {
  width: 1rem !important;
  height: 1rem !important;
}
.disable_btn{
  pointer-events: none;
  opacity: 0.5;
}
</style>
import Vue from 'vue';

export default Vue.mixin({
    data() {
        return {
            url: document.getElementById('base_url').value,
            data_items: [],
            errors: [],
            meta2: {},
            search_text: '',
            toastr_type: '',
            toastr_msg: '',
            toastr_title: '',
            msg_flashed: false,
            loading_btn: false,
            paginate_url: '',
            params: {},
            selected_file: '',
            selected_thumb: '',
            is_select_all: false,
            selected_rows: [],
            selected_files: [],
            images: [],
            thumbnails: [],
            next_page_url: '',
            formData: {
                id: '',
                email: "",
                slug: "",
                title: "",
                password: "",
                first_name: "",
                last_name: "",
                dealership_name: "",
                company_name: "",
                company_phone: "",
                company_address: "",
                company_zip: "",
                job_role: "",
                used_car_lot: "",
                rooftops: "",
                phone: "",
                message: "",
                location: "",
                car_model_id: "",
                password_confirmation: "",
                profile_image: "",
                old_password: "",
                reply: "",
                status: '',
                country_id: "",
                state_id: "",
                city_id: "",
                postal_code: "",
                vin_code: "",
                make_id: "",
                model_id: "",
                year_id: "",
                color_id: "",
                drive_type_id: "",
                transmission_id: "",
                condition_id: "",
                mileage: "",
                fuel_type_id: "",
                engine_size: "",
                type: "",
                door: "",
                cylinders: "",
                body_style_id: "",
                regular_price: "",
                offer_price: "",
                avatar: "",
                details: "",
                gallery_image: [],
                feature_categories: [],
                features: [],
                manufacture: '',
                transmission: '',
                maximum_speed: '',
                url: '',
                image: "",
                super_car_listing: '',
                thumbnail: '',
                state: '',
                city: '',
                user_type: 3,
                user_id: '',
                latitude: "",
                longitude: "",
                license_plate: "",
                name: "",
                ext_color: "",
                color_abbreviation: "",
                trim: "",
                stock_no: "",
                certified: "",
                surface: "",
                trans_gear: "",
                chrome_options: "",
                chrome_style_id: "",
                date_in_stock: "",
                preferred_price: "",
                designation: "",
                description: "",
                contents: "",
                answer: "",
                vendor_type: "",
                deposit_type: "flat",
                deposit_amount: '',
            },
            car_multi_image: [],
            nft_image: ""
        }
    },
    mounted() {

    },
    computed: {
        table_option() {
            return this.$store.getters.getTableOption;
        },
        pageChanger() {
            return this.$store.getters.getCurrentPage;
        },
        authUser() {
            return this.$store.getters.getAuthUser;
        },
        token() {
            return this.$store.getters.getAuthToken;
        }
    },
    watch: {
        $route() {
            this.$store.commit('getFlashResponse', '');
        }
    },
    methods: {
        getUrl(url) {
            return this.url + '/' + url;
        },
        fileUpload(e)
        {
            this.formData.image = e.target.files[0];
        },
        resetForm() {
            for (var key in this.formData) {
                this.formData[key] = '';
            }

            this.errors = [];
            this.images = [];
        },
        toastMessage(type, msg) {
            let flash_response = {
                errors: msg,
                toastr_type: type,
                toastr_title: type.toUpperCase() + '!',
                toastr_msg: msg,
            }
            return this.$store.commit('getFlashResponse', flash_response);
        },

        errorHandle(error) {
            this.loading_btn = false;

            if (error.response) {
                if (error.response.status == 401) {
                    this.toastMessage('error', 'Unauthorized User');
                }
                if (error.response.status == 422) {
                    this.errors = error.response.data.errors;
                    this.toastMessage('error', 'Unauthorized User');
                }
            }
            if (error.response && error.response.statusText) {
                this.toastMessage('error', error.response.statusText);
            }
            // else {
            //     this.toastMessage('error', 'Something Went Wrong');
            // }
        },
        createFormData() {
            this.loading_btn = true;

            let formData = new FormData()

            for (var key in this.formData) {
                if (this.formData[key]) {
                    formData.append(key, this.formData[key]);
                }
            }

            formData.append('token', this.token);

            return formData;
        },
        getItems() {
            this.params.token = this.token;
            this.axios.get(this.getUrl(this.paginate_url), {params: this.params})
                .then(response => {
                    if (response.data.error) {
                        return this.toastMessage('error', response.data.error);
                    } else {
                        this.data_items = response.data.items.data;
                        this.next_page_url = response.data.items.next_page_url;

                        let options = {
                            total_rows: response.data.items.total_rows,
                            page_size: response.data.items.page_size,
                            total_pages: response.data.items.total_pages,
                        };

                        this.$store.commit('setTableOption', options);
                        this.$store.commit('setCar', response.data.items.data);
                        this.$store.commit('setCurrentPage', response.data.items.current_page);

                        this.get_meta2();
                    }
                }).catch(error => {
                return this.errorHandle(error);
            });
        },
        create(url, modal, redirect_url) {
            this.axios.post(this.getUrl(url), this.createFormData(), {
                headers: {
                    'Content-Type': "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2)
                }
            }).then((response) => {
                this.loading_btn = false;
                this.$store.commit('getFlashResponse', []);
                if (response.data.success) {
                    if (modal) {
                        this.$bvModal.hide(modal);
                    }
                    if (modal == 'make') {
                        this.$store.dispatch('carMakes');
                    }
                    if (modal == 'models') {
                        this.$store.dispatch('carModels');
                    }
                    if (modal == 'category') {
                        this.$store.dispatch('featureCategories');
                    }
                    if (modal == 'condition') {
                        this.$store.dispatch('carConditions');
                    }

                    if (redirect_url) {
                        this.$router.push({name: redirect_url});
                    } else {
                        this.getItems();
                        this.resetForm();
                    }
                    this.toastMessage('success', response.data.success);
                } else {
                    this.toastMessage('error', response.data.error);
                }

            }).catch(error => {
                return this.errorHandle(error);
            });
        },

        edit(object, url = null, image,year) {
            this.resetForm();
            if (url) {
                this.axios.get(this.getUrl(url + '?token=' + this.token))
                    .then((response) => {
                        if (response.data.error) {
                            this.toastMessage('error', response.data.error);
                        } else {
                            let result = response.data.item;
                            Object.assign(this.formData, result);

                            if (result.file) {
                                this.selected_file = result.file;
                            }

                            this.selected_thumb = result.selected_thumb;
                            if (image == 1) {
                                this.images = result.files;
                            }
                            if (image == 1) {
                                this.thumbnails = result.thumb_files;
                            }
                        }
                    }).catch(error => {
                    return this.errorHandle(error);
                });
            } else {
                Object.assign(this.formData, object);
                if (image == 1) {
                    this.images = object.files;
                    this.selected_file = object.file;
                }
                if (year == 'year') {
                    // this.formData.car_model_id = '';
                    this.$store.dispatch('carModels',this.formData.make_id);
                }
            }
        },

        change_file(event) {
            this.selected_file = URL.createObjectURL(event.target.files[0]);
            this.formData.image = event.target.files[0];
        },

        singleFile(file) {
            this.formData.image = file.raw;
        },

        removeFile(file) {
            let image;

            if (file.response) {
                image = file.response.file;
            } else {
                image = file.name;
            }
            let url = '';
            if (this.$route.name == 'edit.car')
            {
                url = this.getUrl('remove-file?car=1&file=' + image + '&token=' + this.token+'&id='+this.$route.params.id);
            }
            else{
                url = this.getUrl('remove-file?file=' + image + '&token=' + this.token);
            }

            this.axios.get(url).then(response => {
                if (response.data.error) {
                    this.toastMessage('error', response.data.error);
                } else {
                    this.formData.image = '';
                }
            }).catch(error => {
                return this.errorHandle(error);
            })
        },

        update(url, modal, fetchApi, redirect_url) {
            this.loading_btn = true;
            this.axios.post(this.getUrl(url), this.createFormData(), {
                    headers: {
                        'Content-Type': "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2)
                    }
                }
            ).then((response) => {
                this.loading_btn = false;
                if (response.data.success) {
                    this.resetForm();
                    if (modal) {
                        this.$bvModal.hide(modal);
                    }
                    if (modal == 'make') {
                        this.$store.dispatch('carMakes');
                    }
                    if (fetchApi == 1) {
                        this.getItems();
                    }
                    if (modal == 'models') {
                        this.$store.dispatch('carModels');
                    }
                    if (modal == 'condition') {
                        this.$store.dispatch('carConditions');
                    }
                    if (redirect_url) {
                        this.$router.push({name: redirect_url});
                    }

                    this.toastMessage('success', response.data.success);
                } else {
                    this.toastMessage('error', response.data.error);
                }
            }).catch(error => {
                return this.errorHandle(error);
            })
        },

        destroy(url, id) {
            if (confirm('Are You Sure?')) {
                this.axios.delete(this.getUrl(url + '/' + id + '?token=' + this.token))
                    .then((response) => {
                        if (response.data.success) {
                            this.toastMessage('success', response.data.success);
                            this.getItems();
                        } else {
                            this.toastMessage('error', response.data.error);
                        }
                    }).catch(error => {
                    return this.errorHandle(error);
                });
            }
        },
        batchDestroy(table, image, column_name) {
            if (confirm('Are You Sure?')) {
                let params = '';
                if (image == 1 && column_name) {
                    params = {ids: this.selected_rows, table: table, column: column_name};
                } else {
                    params = {ids: this.selected_rows, table: table};
                }
                this.axios.get(this.getUrl('delete-rows?token=' + this.token), {params: params})
                    .then((response) => {
                        this.selected_rows = [];
                        if (response.data.success) {
                            this.toastMessage('success', response.data.success);
                            this.getItems();
                        } else {
                            this.toastMessage('error', response.data.error);
                        }
                    }).catch(error => {
                    return this.errorHandle(error);
                })
            }
        },

        on_filtered(filtered_items) {
            this.refresh_table(filtered_items.length);
        },
        refresh_table(total) {
            this.table_option.total_rows = total;
            this.$store.commit('setCurrentPage', 1);
            this.get_meta2();
        },
        get_common_meta(table_option) {
            var startPage;
            var endPage;
            var totalPages = table_option.page_size < 1 ? 1 : Math.ceil(table_option.total_rows / table_option.page_size);
            totalPages = Math.max(totalPages || 0, 1);

            var maxSize = table_option.page_size;
            var isMaxSized = typeof maxSize !== "undefined" && maxSize < totalPages;
            if (isMaxSized) {
                startPage = Math.max(
                    this.pageChanger - Math.floor(maxSize / 2),
                    1
                );
                endPage = startPage + maxSize - 1;

                if (endPage > totalPages) {
                    endPage = totalPages;
                    startPage = endPage - maxSize + 1;
                }
            } else {
                startPage = 1;
                endPage = totalPages;
            }
            let startIndex =
                (this.pageChanger - 1) * table_option.page_size;
            let endIndex = Math.min(
                startIndex + table_option.page_size - 1,
                table_option.total_rows - 1
            );

            var pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
                (i) => startPage + i
            );

            return {
                total_items: table_option.total_rows,
                current_page: this.pageChanger,
                page_size: table_option.page_size,
                total_pages: totalPages,
                start_page: startPage,
                end_page: endPage,
                start_index: startIndex,
                end_index: endIndex,
                pages: pages,
            };
        },
        get_meta2() {
            return this.meta2 = this.get_common_meta(this.table_option);
        },
        //checkbox selection
        select_all() {
            this.selected_rows = this.$refs.basic_table.paginatedItems.map((d) => {
                return d.id;
            });

            if (!this.is_select_all) {
                this.clear_selection();
            }
            this.check_select_all();
        },
        check_select_all() {
            let ids = this.$refs.basic_table.paginatedItems.map((d) => {
                return d.id;
            });

            this.is_select_all = ids.length == this.selected_rows.length;
        },
        clear_selection() {
            this.is_select_all = false;
            this.selected_rows = [];
            this.selected_files = [];
        },
        logout() {
            let token = sessionStorage.getItem('token');
            let url = this.getUrl('logout?token=' + token);
            localStorage.removeItem('token');
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('user');
            localStorage.removeItem('user');
            this.$store.commit('setAuthUser', '');
            this.$store.commit('setAuthToken', '');

            this.axios.get(url).then(() => {
                this.$router.push({name: 'login'})
            }).catch(() => {
                this.$router.push({name: 'login'});
            })
        }
    }
})
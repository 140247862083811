<template>
	<div class="layout-px-spacing dash_1">
		<portal to="breadcrumb">
			<ul class="navbar-nav flex-row">
				<li>
					<div class="page-header">
						<nav class="breadcrumb-one" aria-label="breadcrumb">
							<ol class="breadcrumb">
								<li class="breadcrumb-item">
									<a href="javascript:void(0);">Dashboard</a>
								</li>
							</ol>
						</nav>
					</div>
				</li>
			</ul>
		</portal>
    <alert></alert>

    <div class="row layout-top-spacing">
			<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
				<div class="widget widget-revenue">
					<div class="widget-heading">
						<h5>Sales by type</h5>
						<b-dropdown variant="icon-only" toggle-tag="a" :right="true">
							<template #button-content>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									stroke="currentColor"
									stroke-width="2"
									stroke-linecap="round"
									stroke-linejoin="round"
									class="feather feather-more-horizontal"
								>
									<circle cx="12" cy="12" r="1"></circle>
									<circle cx="19" cy="12" r="1"></circle>
									<circle cx="5" cy="12" r="1"></circle>
								</svg>
							</template>
							<b-dropdown-item @click="dashboardRevenue('weekly')" :class="{ 'revenue_active' : active_revenue == 'weekly' }">weekly</b-dropdown-item>
							<b-dropdown-item @click="dashboardRevenue('monthly')" :class="{ 'revenue_active' : active_revenue == 'monthly' }">monthly</b-dropdown-item>
							<b-dropdown-item @click="dashboardRevenue('yearly')" :class="{ 'revenue_active' : active_revenue == 'yearly' }">yearly</b-dropdown-item>
						</b-dropdown>
					</div>

					<div class="widget-content">
						<apexchart
							v-if="revenue_options"
							height="325"
							type="area"
							:options="revenue_options"
							:series="revenue_series"
						></apexchart>
					</div>
				</div>
			</div>
<!--			<div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 layout-spacing">-->
<!--				<div class="widget widget-total-order">-->
<!--					<div class="widget-heading">-->
<!--						<div class="w-icon">-->
<!--							<svg-->
<!--								xmlns="http://www.w3.org/2000/svg"-->
<!--								width="20"-->
<!--								height="20"-->
<!--								viewBox="0 0 24 24"-->
<!--								fill="none"-->
<!--								stroke="currentColor"-->
<!--								stroke-width="2"-->
<!--								stroke-linecap="round"-->
<!--								stroke-linejoin="round"-->
<!--								class="feather feather-shopping-cart"-->
<!--							>-->
<!--								<circle cx="9" cy="21" r="1"></circle>-->
<!--								<circle cx="20" cy="21" r="1"></circle>-->
<!--								<path-->
<!--									d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"-->
<!--								></path>-->
<!--							</svg>-->
<!--						</div>-->
<!--						<div>-->
<!--							<div class="w-value">{{ total_orders }}</div>-->
<!--							<div class="w-numeric-title">Total Orders</div>-->
<!--						</div>-->
<!--					</div>-->
<!--					<div class="widget-content p-0">-->
<!--						<apexchart-->
<!--							v-if="total_orders_options"-->
<!--							height="290"-->
<!--							type="area"-->
<!--							:options="total_orders_options"-->
<!--							:series="total_orders_series"-->
<!--						></apexchart>-->
<!--					</div>-->
<!--				</div>-->
<!--			</div>-->

			<div class="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-12 layout-spacing">
				<div class="widget widget-recent-activity">
					<div class="widget-heading">
						<h5>Recent Activities</h5>
					</div>
					<div class="widget-content">
						<perfect-scrollbar class="timeline-line">
              <div v-for="(log,index) in activity_logs" :key="'log'+index">

                <div v-if="log.type == 2" class="item-timeline  timeline-warning">
                  <div class="badge badge-warning"></div>
                  <div class="t-text">
                    <p>{{ log.title }}</p>
                    <b-badge
                        variant="outline-warning outline-badge-warning icon-fill-warning"
                    >Block</b-badge
                    >
                    <p class="t-time">{{ log.time }}</p>
                  </div>
                </div>

                <div v-if="log.type == 1" class="item-timeline timeline-success">
                  <div class="badge badge-success"></div>
                  <div class="t-text">
                    <p>
                      {{ log.title }}
                    </p>
                    <b-badge
                        variant="outline-success outline-badge-success icon-fill-success"
                    >Completed</b-badge
                    >
                    <p class="t-time">{{ log.time }}</p>
                  </div>
                </div>

                <div v-if="log.type == 4" class="item-timeline  timeline-danger">
                  <div class="badge badge-danger"></div>
                  <div class="t-text">
                    <p>{{ log.title }}</p>
                    <b-badge
                        variant="outline-danger outline-badge-danger icon-fill-danger"
                    >Failed</b-badge
                    >
                    <p class="t-time">{{ log.time }}</p>
                  </div>
                </div>
              </div>

						</perfect-scrollbar>
					</div>
				</div>
			</div>

			<div class="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12 layout-spacing">
				<div class="widget widget-recent-orders">
					<div class="widget-heading">
						<h5>Recent Orders</h5>
					</div>
					<div class="widget-content">
						<b-table-simple responsive>
							<b-thead>
								<b-tr>
									<b-th><div class="th-content">Customer</div></b-th>
									<b-th><div class="th-content">Car Title</div></b-th>
									<b-th><div class="th-content">Invoice</div></b-th>
									<b-th><div class="th-content th-heading">Price</div></b-th>
									<b-th><div class="th-content">Status</div></b-th>
								</b-tr>
							</b-thead>
							<b-tbody>
								<b-tr v-for="(order,index) in recent_orders" :key="index">
									<b-td><div class="td-content">
											<img
												:src="order.user_image"
												:alt="order.user_name"
											/><span>{{ order.user_name }}</span>
										</div></b-td
									>
									<b-td
										><div class="td-content">
											{{ order.car }} <br />
											<span class="text-warning" v-if="order.type == 'used'">{{ order.car_type }}</span>
											<span class="text-success" v-else>{{ order.car_type }}</span>
										</div></b-td
									>
									<b-td><div class="td-content">#{{ order.invoice_no }}</div></b-td>
									<b-td
										><div class="td-content"><span>${{ order.total_payable }}</span></div></b-td
									>
									<b-td
										><div class="td-content">
											<b-badge :variant="order.variant">{{ order.status }}</b-badge>
										</div></b-td
									>
								</b-tr>
                <b-tr v-if="recent_orders.length == 0 && !loading_btn">
                  <b-td class="text-center text-danger" colspan="5">No Item Found</b-td>
                </b-tr>
							</b-tbody>
						</b-table-simple>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// import store from '../store';
	import Vue from "vue";
	import VueApexCharts from "vue-apexcharts";
	Vue.use(VueApexCharts);
	Vue.component("apexchart", VueApexCharts);

	import "@/assets/sass/widgets/widgets.scss";

	export default {
		metaInfo: { title: "Dashboard" },
		data() {
			return {
				//Total Orders
				total_orders_series: [
					{
            name: "Sales",
            data: [28, 40, 36, 52, 38, 60, 38, 52, 36, 40]
          },
				],
        labels : [],
        active_revenue : 'monthly',
				//sales by type
				revenue_series: [
					{
						name: "New Cars",
						data: [
							16800,
							16800,
							15500,
							17800,
							15500,
							17000,
							19000,
							16000,
							15000,
							17000,
							14000,
							17000,
						],
					},
					{
						name: "Used Cars",
						data: [
							16500,
							17500,
							16200,
							17300,
							16000,
							19500,
							16000,
							17000,
							16000,
							19000,
							18000,
							19000,
						],
					},
				],
        recent_orders : [],
        activity_logs : [],
        total_orders : 0,
        page: 1,
      };
		},
		computed: {
      revenue_options() {
        const is_dark = this.$store.state.is_dark_mode;
        return {
          chart: {
            fontFamily: "Nunito, sans-serif",
            zoom: { enabled: false },
            toolbar: { show: false },
            events: {
              mounted: function(ctx) {
                const highest1 = ctx.getHighestValueInSeries(0);
                const highest2 = ctx.getHighestValueInSeries(1);

                ctx.addPointAnnotation({
                  x: "Jul",
                  y: highest1,
                  label: { style: { cssClass: "d-none" } },
                  customSVG: {
                    SVG:
                        '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="#1b55e2" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle"><circle cx="12" cy="12" r="10"></circle></svg>',
                    cssClass: undefined,
                    offsetX: -8,
                    offsetY: 7,
                  },
                });

                ctx.addPointAnnotation({
                  x: "Jun",
                  y: highest2,
                  label: { style: { cssClass: "d-none" } },
                  customSVG: {
                    SVG:
                        '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="#e7515a" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" class="feather feather-circle"><circle cx="12" cy="12" r="10"></circle></svg>',
                    cssClass: undefined,
                    offsetX: -8,
                    offsetY: 7,
                  },
                });
              },
            },
          },
          dataLabels: { enabled: false },
          stroke: { show: true, curve: "smooth", width: 2, lineCap: "square" },
          dropShadow: {
            enabled: true,
            opacity: 0.2,
            blur: 10,
            left: -7,
            top: 22,
          },
          colors: is_dark ? ["#2196f3", "#e7515a"] : ["#44ae67", "#ffa200"],
          markers: {
            discrete: [
              {
                seriesIndex: 0,
                dataPointIndex: 7,
                fillColor: "#000",
                strokeColor: "#000",
                size: 5,
              },
              {
                seriesIndex: 2,
                dataPointIndex: 11,
                fillColor: "#000",
                strokeColor: "#000",
                size: 4,
              },
            ],
          },
          labels: this.labels,
          xaxis: {
            axisBorder: { show: false },
            axisTicks: { show: false },
            crosshairs: { show: true },
            labels: {
              offsetX: 0,
              offsetY: 5,
              style: {
                fontSize: "12px",
                fontFamily: "Nunito, sans-serif",
                cssClass: "apexcharts-xaxis-title",
              },
            },
          },
          yaxis: {
            tickAmount: 7,
            labels: {
              formatter: function(value) {
                return value / 1000 + "K";
              },
              offsetX: -10,
              offsetY: 0,
              style: {
                fontSize: "12px",
                fontFamily: "Nunito, sans-serif",
                cssClass: "apexcharts-yaxis-title",
              },
            },
          },
          grid: {
            borderColor: is_dark ? "#191e3a" : "#e0e6ed",
            strokeDashArray: 5,
            xaxis: { lines: { show: true } },
            yaxis: { lines: { show: false } },
            padding: { top: 0, right: 0, bottom: 0, left: 0 },
          },
          legend: {
            position: "top",
            horizontalAlign: "right",
            offsetY: 0,
            fontSize: "16px",
            fontFamily: "Nunito, sans-serif",
            markers: {
              width: 10,
              height: 10,
              strokeWidth: 0,
              strokeColor: "#fff",
              fillColors: undefined,
              radius: 12,
              onClick: undefined,
              offsetX: 0,
              offsetY: 0,
            },
            itemMargin: { horizontal: 20, vertical: 5 },
          },
          tooltip: {
            theme: "dark",
            marker: { show: true },
            x: { show: false },
          },
          fill: {
            type: "gradient",
            gradient: {
              type: "vertical",
              shadeIntensity: 1,
              inverseColors: !1,
              opacityFrom: is_dark ? 0.19 : 0.28,
              opacityTo: 0.05,
              stops: is_dark ? [100, 100] : [45, 100],
            },
          },
        };
      },
			//Total Orders
			total_orders_options() {
				const is_dark = this.$store.state.is_dark_mode;
				return {
					chart: { sparkline: { enabled: true } },
					stroke: { curve: "smooth", width: 2 },
					colors: is_dark ? ["#1abc9c"] : ["#fff"],
					labels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
					yaxis: { min: 0, show: false },
					grid: { padding: { top: 125, right: 0, bottom: 0, left: 0 } },
					fill: {
						opacity: 1,
						type: "gradient",
						gradient: {
							type: "vertical",
							shadeIntensity: 1,
							inverseColors: !1,
							opacityFrom: is_dark ? 0.3 : 0.4,
							opacityTo: 0.05,
							stops: is_dark ? [100, 100] : [45, 100],
						},
					},
					tooltip: { x: { show: false }, theme: "dark" },
				};
			},
		},
    /*beforeRouteEnter(to, from) {
      if (from.name != 'login')
      {
        store.commit('getFlashResponse','');
      }
      /!*next(vm => {
        vm.prevRoute = from
      })*!/
    },*/
		mounted() {
      this.$store.commit('getFlashResponse','');
      this.dashboardRevenue('monthly');
      this.dashboardData();
      this.activityLogs();
      window.document.onscroll = () => {
        if (this.next_page_url && !this.loading_btn) {
          this.loadMore();
        }
      }
    },
		methods: {
      dashboardRevenue(type)
      {
        this.active_revenue = type;
        let url = this.getUrl('dashboard-revenue');
        this.params.token = this.token;
        this.params.type = type;
        this.axios.get(url,{params : this.params}).then(response =>{
          if (response.data.error)
          {
            this.toastMessage('error',response.data.error);
          }
          else{
            let data = response.data;
            this.revenue_series = data.revenue;
            this.labels = data.labels;
          }
        })
      },
      dashboardData()
      {
        this.loading_btn = true;
        let url = this.getUrl('dashboard-data');
        this.params.token = this.token;
        this.axios.get(url,{params : this.params}).then(response =>{
          this.loading_btn = false;
          if (response.data.error)
          {
            this.toastMessage('error',response.data.error);
          }
          else{
            let data = response.data;
            this.total_orders_series = data.total_sale;
            this.recent_orders = data.recent_orders.data;
            this.total_orders = data.total_order;
          }
        }).catch(error =>{
          this.errorHandle(error);
        })
      },
      activityLogs()
      {
        this.loading_btn = true;
        let url = this.getUrl('activity-logs');
        this.params.token = this.token;
        this.axios.get(url,{params : this.params}).then(response =>{
          this.loading_btn = false;
          if (response.data.error)
          {
            this.toastMessage('error',response.data.error);
          }
          else{
            let data = response.data;
            this.activity_logs = data.logs.data;
            this.next_page_url = data.logs.next_page_url;
          }
        }).catch(error =>{
          this.errorHandle(error);
        })
      },
      loadMore() {
        this.page++;
        this.params.page = this.page;
        this.params.token = this.token;
        this.loading_btn = true;
        this.axios.get(this.getUrl('activity-logs'), {params: this.params})
            .then(response => {
              this.loading_btn = false;
              if (response.data.error) {
                return this.toastMessage('error',response.data.error);
              } else {
                let items = response.data.logs.data;
                this.next_page_url = response.data.logs.next_page_url;
                for (let i = 0; i < items.length; i++) {
                  this.activity_logs.push(items[i]);
                }
              }
            }).catch(error => {
          return this.errorHandle(error);
        });
      }
    },
	};
</script>
<style>
.revenue_active{
  color: #2196f3;
  background: #f8f8f8;
}
.dropdown-item{
  text-transform : capitalize !important;
}
</style>
<template>
    <div>
         <div class="overlay" :class="{ show: isOpen }" @click="isOpen = !isOpen"></div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                isOpen: null
            };
        },

        mounted() {
            //set default values from local storage
            this.setTemplateStyle();
            this.setMenuStyle();
            this.setLayoutStyle();
        },

        methods: {
            setTemplateStyle() {
                this.$store.commit('toggleDarkMode', this.$store.state.dark_mode);
            },

            setMenuStyle() {
                this.$store.commit('toggleMenuStyle', this.$store.state.menu_style);
            },

            setLayoutStyle() {
                this.$store.commit('toggleLayoutStyle', this.$store.state.layout_style);
            }
        }
    };
</script>

import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/index.vue';
// import store from '../store';

Vue.use(VueRouter);

const routes = [
    //dashboard
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: {
            auth: true
        }
    },

    //auth
    {
        path: "/login",
        name: "login",
        component: () =>
            import(/* webpackChunkName: "auth-login" */ "../views/auth/login.vue"),
        meta: {
            layout: "auth",
        },
    },

    {
        path: "/forget-password",
        name: "forget-password",
        component: () =>
            import(
                /* webpackChunkName: "forget-password" */ "../views/auth/pass_recovery.vue"
                ),
        meta: {
            layout: "auth",
        },
    },

    //elements
    {
        path: "/loader",
        name: "loader",
        component: () =>
            import(
                /* webpackChunkName: "elements-loader" */ "../views/elements/loader.vue"
                ),
    },

    //users
    {
        path: "/users/edit/:id",
        name: "user.profile",
        component: () =>
            import(
                /* webpackPrefetch: true */ "../views/users/profile.vue"
                ),
        meta: {
            auth: true
        }
    },
    //users
    {
        path: "/users/profile",
        name: "profile",
        component: () =>
            import(
                /* webpackPrefetch: true */ "../views/users/auth-profile"
                ),
        meta: {
            auth: true
        }
    },
    {
        path: "/users/dealer-listing/:id",
        name: "dealer.listing",
        component: () =>
            import(
                /* webpackChunkName: "dealer-listing" */ "../views/users/dealer-listing.vue"
                ),
        meta: {
            auth: true
        }
    },
    {
        path: "/users/change-password",
        name: "change-password",
        component: () =>
            import(
                /* webpackChunkName: "change-password" */ "../views/users/change-password.vue"
                ),
        meta: {
            auth: true
        }
    },
    {
        path: "/users/seller",
        name: "seller",
        component: () =>
            import(/* webpackChunkName: "seller" */ "../views/users/sellers.vue"),
        meta: {
            auth: true
        }
    },
    {
        path: "/users/buyer",
        name: "buyer",
        component: () =>
            import(/* webpackChunkName: "buyer" */ "../views/users/buyers.vue"),
        meta: {
            auth: true
        }
    },
    {
        path: "/users/admin",
        name: "admin",
        component: () =>
            import(/* webpackChunkName: "admin" */ "../views/users/admin.vue"),
        meta: {
            auth: true
        }
    },
    //nft
    {
        path: "/nft/list",
        name: "nft",
        component: () =>
            import(/* webpackChunkName: "nft" */ "../views/nft/nft.vue"),
        meta: {
            auth: true
        }
    },
    {
        path: "/nft/edit/:id",
        name: "nft.edit",
        component: () =>
            import(/* webpackChunkName: "nft-edit" */ "../views/nft/editnft.vue"),
        meta: {
            auth: true
        }
    },

    //listing cars
    {
        path: "/listing/new-cars",
        name: "new-cars",
        component: () =>
            import(
                /* webpackChunkName: "new-cars" */ "../views/listing/new-cars.vue"
                ),
        meta: {
            auth: true
        }
    },
    {
        path: "/listing/used-cars",
        name: "used-cars",
        component: () =>
            import(
                /* webpackChunkName: "used-cars" */ "../views/listing/used-cars.vue"
                ),
        meta: {
            auth: true
        }
    },
    {
        path: "/listing/super-cars",
        name: "super-cars",
        component: () =>
            import(
                /* webpackChunkName: "supper-cars" */ "../views/listing/super-cars.vue"
                ),
        meta: {
            auth: true
        }
    },
    {
        path: "/listing/edit-car/:id",
        name: "edit.car",
        component: () =>
            import(
                /* webpackChunkName: "edit-car" */ "../views/listing/edit-car.vue"
                ),
        meta: {
            auth: true
        }
    },

    //apps

    {
        path: "/apps/mailbox",
        name: "mailbox",
        component: () =>
            import(
                /* webpackChunkName: "apps-mailbox" */ "../views/apps/mailbox.vue"
                ),
        meta: {
            auth: true
        }
    },
    //order
    {
        path: "/order/list",
        name: "order-list",
        component: () =>
            import(/* webpackChunkName: "order-list" */ "../views/order/list.vue"),
        meta: {
            auth: true
        }
    },
    {
        path: "/order/preview/:id",
        name: "order.preview",
        component: () =>
            import(
                /* webpackChunkName: "order-preview" */ "../views/order/preview.vue"
                ),
        meta: {
            auth: true
        }
    },
    {
        path: "/order/edit/:id",
        name: "order.edit",
        component: () =>
            import(/* webpackChunkName: "order-edit" */ "../views/order/edit.vue"),
        meta: {
            auth: true
        }
    },
    //car info
    {
        path: "/car/makes",
        name: "car-makes",
        component: () =>
            import(/* webpackChunkName: "car-makes" */ "../views/car/makes.vue"),
        meta: {
            auth: true
        }
    },
    {
        path: "/car/models",
        name: "car-models",
        component: () =>
            import(/* webpackChunkName: "car-models" */ "../views/car/models.vue"),
        meta: {
            auth: true
        }
    },
    {
        path: "/car/years",
        name: "car-years",
        component: () =>
            import(/* webpackChunkName: "car-years" */ "../views/car/years.vue"),
        meta: {
            auth: true
        }
    },
    {
        path: "/car/colors",
        name: "car-colors",
        component: () =>
            import(/* webpackChunkName: "car-colors" */ "../views/car/colors.vue"),
        meta: {
            auth: true
        }
    },
    {
        path: "/car/transmission",
        name: "car-transmission",
        component: () =>
            import(
                /* webpackChunkName: "car-transmission" */ "../views/car/transmission.vue"
                ),
        meta: {
            auth: true
        }
    },
    {
        path: "/car/body-style",
        name: "body-style",
        component: () =>
            import(
                /* webpackChunkName: "body-style" */ "../views/car/body-style.vue"
                ),
        meta: {
            auth: true
        }
    },
    {
        path: "/car/condition",
        name: "condition",
        component: () =>
            import(/* webpackChunkName: "condition" */ "../views/car/condition.vue"),
        meta: {
            auth: true
        }
    },
    {
        path: "/car/car-features",
        name: "car-features",
        component: () =>
            import(
                /* webpackChunkName: "car-features" */ "../views/car/car-features.vue"
                ),
        meta: {
            auth: true
        }
    },
    {
        path: "/car/fuel-type",
        name: "fuel-type",
        component: () =>
            import(/* webpackChunkName: "fuel-type" */ "../views/car/fuel-type.vue"),
        meta: {
            auth: true
        }
    },
    {
        path: "/car/drive-type",
        name: "drive-type",
        component: () =>
            import(/* webpackChunkName: "drive-type" */ "../views/car/drive.vue"),
        meta: {
            auth: true
        }
    },
    {
        path: "/users/sellers-review/:id",
        name: "sellers.review",
        component: () =>
            import(
                /* webpackChunkName: "sellers-review" */ "../views/users/sellers-review.vue"
                ),
        meta: {
            auth: true
        }
    },
    {
        path: "/car/car-comments/:id",
        name: "car.comments",
        component: () =>
            import(
                /* webpackChunkName: "car-comments" */ "../views/car/car-comments.vue"
                ),
        meta: {
            auth: true
        }
    },

    //pages
    {
        path: "/pages/faqs",
        name: "faqs",
        component: () =>
            import(/* webpackChunkName: "faqs" */ "../views/pages/faq.vue"),
        meta: {
            auth: true
        }
    },
    {
        path: "/pages/teams",
        name: "teams",
        component: () =>
            import(/* webpackChunkName: "faqs" */ "../views/pages/team.vue"),
        meta: {
            auth: true
        }
    },
    {
        path: "/pages/testimonials",
        name: "testimonial",
        component: () =>
            import(/* webpackChunkName: "faqs" */ "../views/pages/testimonial.vue"),
        meta: {
            auth: true
        }
    },
    {
        path: "/footer/page",
        name: "footer.page",
        component: () =>
            import(/* webpackChunkName: "faqs" */ "../views/pages/page.vue"),
        meta: {
            auth: true
        }
    },
    {
        path: "/pages/addfaqs",
        name: "addfaqs",
        component: () =>
            import(/* webpackChunkName: "addfaqs" */ "../views/pages/addFaq.vue"),
        meta: {
            auth: true
        }
    },
    {
        path: "/pages/editfaqs/:id",
        name: "edit.faqs",
        component: () =>
            import(/* webpackChunkName: "editfaqs" */ "../views/pages/editFaq.vue"),
        meta: {
            auth: true
        }
    },
    {
        path: "/pages/add-page",
        name: "add.page",
        component: () =>
            import(/* webpackChunkName: "addfaqs" */ "../views/pages/addPage.vue"),
        meta: {
            auth: true
        }
    },
    {
        path: "/pages/edit-page/:id",
        name: "edit.page",
        component: () =>
            import(/* webpackChunkName: "editfaqs" */ "../views/pages/editPage.vue"),
        meta: {
            auth: true
        }
    },

    {
        path: "/pages/blog",
        name: "blog",
        component: () =>
            import(/* webpackChunkName: "blog" */ "../views/pages/blog.vue"),
        meta: {
            auth: true
        }
    },
    {
        path: "/pages/addblog",
        name: "addblog",
        component: () =>
            import(/* webpackChunkName: "addblog" */ "../views/pages/addBlog.vue"),
        meta: {
            auth: true
        }
    },
    {
        path: "/pages/editblog/:id",
        name: "edit.blog",
        component: () =>
            import(/* webpackChunkName: "editblog" */ "../views/pages/editBlog.vue"),
        meta: {
            auth: true
        }
    },
    {
        path: "/pages/blog-category",
        name: "blog-category",
        component: () =>
            import(
                /* webpackChunkName: "blog-category" */ "../views/pages/post-category.vue"
                ),
        meta: {
            auth: true
        }
    },
    {
        path: "/dealer-requests",
        name: "dealer.requests",
        component: () =>
            import(
                /* webpackChunkName: "dealer-requests" */ "../views/users/dealer-requests"
                ),
        meta: {
            auth: true
        }
    },
    {
        path: "/settings",
        name: "settings",
        component: () =>
            import(
                /* webpackChunkName: "dealer-requests" */ "../views/settings.vue"
                ),
        meta: {
            auth: true
        }
    },

    //error
    {
        path: "/*",
        name: "error404",
        component: () =>
            import(
                /* webpackChunkName: "pages-error404" */ "../views/pages/error404.vue"
                ),
        meta: {layout: "auth"},
    },
];

const router = new VueRouter({
    mode: 'history',
    linkExactActiveClass: 'active',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return {x: 0, y: 0};
        }
    }
});


/*router.beforeEach((to, from, next) => {

    next(true);
});*/

export default router;

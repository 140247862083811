<template>
	<div>
		<!--  BEGIN NAVBAR  -->
		<div class="header-container fixed-top">
			<header class="header navbar navbar-expand-sm">
				<ul class="navbar-item theme-brand flex-row text-center">
					<li class="nav-item theme-logo">
						<router-link to="/">
							<img
								src="@/assets/images/logo.svg"
								class="navbar-logo"
								alt="logo"
							/>
						</router-link>
					</li>
				</ul>
				<div class="d-none horizontal-menu">
					<a
						href="javascript:void(0);"
						class="sidebarCollapse"
						data-placement="bottom"
						@click="
							$store.commit('toggleSideBar', !$store.state.is_show_sidebar)
						"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							stroke="currentColor"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
							class="feather feather-menu"
						>
							<line x1="3" y1="12" x2="21" y2="12"></line>
							<line x1="3" y1="6" x2="21" y2="6"></line>
							<line x1="3" y1="18" x2="21" y2="18"></line>
						</svg>
					</a>
				</div>

				<div class="navbar-item flex-row ml-md-auto" v-if="authUser">
					<b-dropdown
						toggle-tag="a"
						variant="icon-only"
						toggle-class="user nav-link"
						class="nav-item user-profile-dropdown"
						:right="true"
					>
						<template #button-content>
							<img v-if="authUser.profile_image" :src="authUser.profile_image" alt="avatar" />
						</template>

						<b-dropdown-item to="/users/profile">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
								class="feather feather-user"
							>
								<path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
								<circle cx="12" cy="7" r="4"></circle>
							</svg>
							My Account
						</b-dropdown-item>

						<b-dropdown-item to="/users/change-password">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
								class="feather feather-inbox"
							>
								<polyline points="22 12 16 12 14 15 10 15 8 12 2 12"></polyline>
								<path
									d="M5.45 5.11L2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z"
								></path>
							</svg>
							Change Password
						</b-dropdown-item>

						<b-dropdown-divider></b-dropdown-divider>
						<b-dropdown-item @click="logout">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								stroke-width="2"
								stroke-linecap="round"
								stroke-linejoin="round"
								class="feather feather-log-out"
							>
								<path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
								<polyline points="16 17 21 12 16 7"></polyline>
								<line x1="21" y1="12" x2="9" y2="12"></line>
							</svg>
							Log Out
						</b-dropdown-item>
					</b-dropdown>
				</div>
			</header>
		</div>
		<!--  END NAVBAR  -->
		<!--  BEGIN NAVBAR  -->
		<div class="sub-header-container">
			<header class="header navbar navbar-expand-sm">
				<a
					href="javascript:void(0);"
					class="sidebarCollapse"
					data-placement="bottom"
					@click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						stroke="currentColor"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
						class="feather feather-menu"
					>
						<line x1="3" y1="12" x2="21" y2="12"></line>
						<line x1="3" y1="6" x2="21" y2="6"></line>
						<line x1="3" y1="18" x2="21" y2="18"></line>
					</svg>
				</a>

				<!-- Portal vue for Breadcrumb -->
				<portal-target name="breadcrumb"> </portal-target>
			</header>
		</div>
		<!--  END NAVBAR  -->
	</div>
</template>
<script>
	export default {
		data() {
			return {
				selectedLang: null,
				countryList: this.$store.state.countryList,
			};
		},
		mounted() {
			// this.selectedLang = this.$appSetting.toggleLanguage();
			// this.toggleMode();
      this.checkUser();
		},
		methods: {
			toggleMode(mode) {
				this.$appSetting.toggleMode(mode);
			},

			changeLanguage(item) {
				this.selectedLang = item;
				this.$appSetting.toggleLanguage(item);
			},
      checkUser()
      {
        let token = this.token;
        let url = this.getUrl('profile?token='+token);
        this.axios.get(url).then((response) =>{
          if (response.data.error)
          {
            this.toastMessage('error','Something Went Wrong, Please Login Again');
            this.$router.push({ name : 'login' });
          }
          else{
            let user = response.data.user;
            if (user.user_type != this.authUser.user_type)
            {
              this.toastMessage('error','Something Went Wrong, Please Login Again');
              this.$router.push({ name : 'login' });
            }
          }
        }).catch(error => {
          this.errorHandle(error);
          this.$router.push({ name : 'login' });
        });
      }
		},
	};
</script>
<style>
.error-border{
  border: 1px solid red;
  border-radius: 5px;
}
</style>

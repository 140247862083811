<template>
	<!-- BEGIN FOOTER -->
	<div class="footer-wrapper">
		<div class="footer-section f-section-1">
			<p class="">Crypto Car Trader © 2023. All Rights Reserved.</p>
		</div> 
	</div>
	<!-- END FOOTER -->
</template>
<script>
	export default {
		data() {
			return {};
		},
		mounted() {
    },
		methods: {},
	};
</script>
